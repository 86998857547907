<template>
  <vue-perfect-scrollbar class="h-without-header">
    <v-list dense rounded class="pa-4">
      <v-list-item-group>
        <div class="fs-10 py-5 pl-2 fw-bold">基本設定</div>
        <v-list-item
          v-for="item in settings_items"
          :key="`${item.link}`"
          @click="selectSetting(item.link)"
          color="blue"
          dense
        >
          <v-list-item-content class="fs-11 px-2" v-text="$t(item.title)" />
        </v-list-item>

        <v-divider class="my-4" />
        <div class="fs-10 py-5 pl-2 fw-bold">他システム連携</div>
        <v-list-item
          v-for="item in reg_items"
          :key="`${item.link}`"
          @click="selectSetting(item.link)"
          color="blue"
          dense
        >
          <v-list-item-content class="fs-11 px-2" v-text="$t(item.title)" />
        </v-list-item>

        <v-divider class="my-4" />
        <div class="fs-10 py-5 pl-2 fw-bold">ユーザ／権限</div>
        <v-list-item
          v-for="item in user_items"
          :key="`${item.link}`"
          @click="selectSetting(item.link)"
          color="blue"
          dense
        >
          <v-list-item-content class="fs-11 px-2" v-text="$t(item.title)" />
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </vue-perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCurrentAppLayout } from 'Helpers/helpers'

export default {
  computed: {
    ...mapGetters(['mailboxes']),
  },
  props: {
    selectSetting: Function,
    selectedItem: null,
  },
  methods: {
    getScrollHeight() {
      let layout = getCurrentAppLayout(this.$router)
      switch (layout) {
        default:
          return 'height:calc(100vh - 50px)'
      }
    },
    checkSelected(val) {
      try {
        if (this.selectedItem) {
          return val === this.selectedItem
        } else {
          return false
        }
      } catch {
        return false
      }
    },
  },
  data() {
    return {
      dialog: false,
      settings_items: [
        {
          link: 'myCompany',
          title: '会社情報',
        },
        {
          link: 'item',
          title: '管理項目／承認',
        },
        {
          link: 'defaultJournal',
          title: 'デフォルト仕訳／支払',
        },
        {
          link: 'userBank',
          title: '振込元銀行口座',
        },
        {
          link: 'ip',
          title: 'IP制限',
        },
        {
          link: 'plan',
          title: 'プラン',
        },
      ],
      reg_items: [
        {
          link: 'exportJournal',
          title: '仕訳エクスポート',
        },
        {
          link: 'mailUpload',
          title: 'メール取込',
        },
        {
          link: 'sns',
          title: 'Slack・LINE連携',
        },
        {
          link: 'freee',
          title: 'freee同期',
        },
      ],
      user_items: [
        {
          link: 'user',
          title: 'ユーザ一覧',
        },
        {
          link: 'role',
          title: '権限マスタ',
        },
      ],
    }
  },
  components: {},
}
</script>
